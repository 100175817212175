<template>
  <b-overlay :show="loading">
    <validation-observer ref="newUserForm" #default="{invalid}">
      <b-form ref="form" @submit.prevent="createNewUser">
        <b-row>
          <b-col
            cols="12"
            xl="6"
            md="6"
            >
            <b-card no-body class="">
              <b-card-body class="pt-2">
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input 
                        id="first_name" 
                        v-model="newUserData.first_name" 
                        :state="errors.length > 0 ? false : null"
                        />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-input 
                        id="last_name" 
                        v-model="newUserData.last_name" 
                        :state="errors.length > 0 ? false : null"
                        />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group label="User Roles" label-for="user_roles">
                  <validation-provider
                    #default="{ errors }"
                    name="User Roles"
                    rules=""
                  >
                    <v-select
                      v-model="newUserData.user_roles"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userRoleOptions"
                      :reduce="(val) => val.value"
                      :clearable="true"
                      :close-on-select="false"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group
                  label="User Permission Policies"
                  label-for="user_role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="User Permission Policies"
                      rules="required"
                    >
                      <v-select
                        v-model="newUserData.permissions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="policyOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        :close-on-select="false"
                        multiple
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                    vid="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="newUserData.email"
                      :state="errors.length > 0 ? false:null"
                      name="email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>

              <b-card-body class="pt-0">
                <b-form-group
                  label="Phone"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required"
                    vid="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="newUserData.phone"
                      :state="errors.length > 0 ? false:null"
                      name="register-phone"
                      placeholder="0201234567"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-card-body>


              <b-button
                class="mb-5 mx-2"
                style="width: 150px"
                variant="primary"
                block
                type="submit"
                :disabled="invalid || loading"
              >
                Create User
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { 
  BOverlay, BRow, BCol, BCard, BCardBody, BButton, 
  BFormGroup, BForm, BFormInput
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { get, cloneDeep, omit } from 'lodash';

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawUserData = {
  first_name: "",
  last_name: "",
  permissions: "",
  email: "",
  phone: "",
  user_roles: []
}

export default {
  components: {
    vSelect,
    BOverlay, 
    BRow, 
    BCol, 
    BCard, 
    BCardBody, 
    BButton,
    BFormGroup, 
    BForm, 
    BFormInput,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data(){
    return {
      loading: false,
      errorMessage: '',
      newUserData: cloneDeep(rawUserData),
      userTypeOptions: [],
      policyOptions: [],
      userRoleOptions: [],

      // validation rules
      required,
      email
    }
  },
  created() {
    this.fetchPermissions();
    this.fetchAdminRoles();
  },
  methods: {
    async fetchPermissions() {
      try {
        this.loading = true; 

        const query = {
          limit: 1000,
          page: 1
        }

        const response = await this.useJwt().adminService.fetchAdminPolicies(query);
        this.policyOptions = get(response, 'data.data', []).map(pm => ({ ...pm, label: pm.name, value: pm._id }))
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async createNewUser(){
      try {
        this.loading = true;
        this.errorMessage = '';
        
        const success = await this.$refs.newUserForm.validate();
        if (!success){ return; }

        const policies = this.newUserData.permissions.map(permission_id => ({ permission_id, permission_type: "policy" }))
        const roles = this.newUserData.user_roles.map(permission_id => ({ permission_id, permission_type: "role" }))
        await this.useJwt().adminService.createAdmin({ ...omit(this.newUserData, ['user_roles']), permissions: [ ...policies, ...roles ] });
        this.resetForm();
    
        await this.$router.push({
          name: "admin"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'EditIcon',
            variant: 'success',
            text: `User created successfully. A password reset link will be sent to their email address.`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async fetchAdminRoles() {
      try {
        this.loading = true;

        const query = {
          limit: 1000,
          page: 1,
        };

        const response = await this.useJwt().adminService.getUserRoles(query);
        this.userRoleOptions = get(response, "data.data", []).map((pm) => ({
          ...pm,
          label: pm.title,
          value: pm._id,
        }));
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    resetForm(){
      Object.keys(cloneDeep(this.newUserData)).forEach(key => {
        const resetValue = get(rawUserData, key);
        this.newUserData[key] = resetValue
        this.$set(this.newUserData, resetValue)
      });
    }
  }
}
</script>
