var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('validation-observer',{ref:"newUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.createNewUser.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pt-2"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","state":errors.length > 0 ? false : null},model:{value:(_vm.newUserData.first_name),callback:function ($$v) {_vm.$set(_vm.newUserData, "first_name", $$v)},expression:"newUserData.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","state":errors.length > 0 ? false : null},model:{value:(_vm.newUserData.last_name),callback:function ($$v) {_vm.$set(_vm.newUserData, "last_name", $$v)},expression:"newUserData.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"User Roles","label-for":"user_roles"}},[_c('validation-provider',{attrs:{"name":"User Roles","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.userRoleOptions,"reduce":function (val) { return val.value; },"clearable":true,"close-on-select":false,"multiple":""},model:{value:(_vm.newUserData.user_roles),callback:function ($$v) {_vm.$set(_vm.newUserData, "user_roles", $$v)},expression:"newUserData.user_roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"User Permission Policies","label-for":"user_role"}},[_c('validation-provider',{attrs:{"name":"User Permission Policies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.policyOptions,"reduce":function (val) { return val.value; },"clearable":true,"close-on-select":false,"multiple":""},model:{value:(_vm.newUserData.permissions),callback:function ($$v) {_vm.$set(_vm.newUserData, "permissions", $$v)},expression:"newUserData.permissions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":"john@example.com"},model:{value:(_vm.newUserData.email),callback:function ($$v) {_vm.$set(_vm.newUserData, "email", $$v)},expression:"newUserData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-body',{staticClass:"pt-0"},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"register-phone","placeholder":"0201234567"},model:{value:(_vm.newUserData.phone),callback:function ($$v) {_vm.$set(_vm.newUserData, "phone", $$v)},expression:"newUserData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-button',{staticClass:"mb-5 mx-2",staticStyle:{"width":"150px"},attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.loading}},[_vm._v(" Create User ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }